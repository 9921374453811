import { BlogNode, blogNodeToCmsNode } from "@components/CMS/CmsTypes";
import CmsFeaturedImage from "@components/CMS/components/CmsFeaturedImage/CmsFeaturedImage";
import { TypographyV2 } from "@components/index";
import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import * as styles from "./BlogsHero.module.scss";

type Props = {
	firstBlog: BlogNode;
	showReadMoreButton?: boolean;
	showFullTitle?: boolean;
};

const BlogsHero = ({
	firstBlog,
	showReadMoreButton = true,
	showFullTitle,
}: Props) => {
	if (!firstBlog) {
		return <></>;
	}
	const blog = blogNodeToCmsNode(firstBlog);
	return (
		<div className={styles.container}>
			<CmsFeaturedImage node={blog} className={styles.image} />
			<span className={styles.titleAndSummary}>
				<TypographyV2
					variant="HEADING_1"
					weight="SEMIBOLD"
					tabletVariant="HEADING_4"
					mobileVariant="HEADING_5"
					className={classNames({ [styles.title]: !showFullTitle })}
					style={{ marginBottom: 12 }}
				>
					{firstBlog.title}
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="gray-600"
					className={styles.summary}
				>
					{firstBlog.body.summary}
				</TypographyV2>
				<div className={styles.footer}>
					<TypographyV2
						variant="BODY_TEXT_LARGE"
						tabletVariant="BODY_TEXT_SMALL"
						mobileVariant="BODY_TEXT_SMALL"
						weight="REGULAR"
						style={{ marginBottom: 0 }}
					>
						{blog.created}
					</TypographyV2>
					<Link to={blog.path.alias}>
						{showReadMoreButton && (
							<div className={styles.button}>
								<TypographyV2
									variant="BODY_TEXT_MEDIUM"
									weight="MEDIUM"
									color="brand-600"
								>
									Read more
								</TypographyV2>
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6 18.668L18 6.66797M18 6.66797H10M18 6.66797V14.668"
										stroke="#051D85"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						)}
					</Link>
				</div>
			</span>
		</div>
	);
};

export default BlogsHero;
