export const ENVIRONMENT = (process.env.GATSBY_ENV || "production") as
	| "development"
	| "production";

const APP_URLS = {
	development: "http://localhost:3000",
	production: "https://app.genemod.net",
} as const;

export const APP_URL = APP_URLS[ENVIRONMENT];

const APP_BACKEND_URLS = {
	development: "http://localhost:8000",
	production: "https://api.genemod.net",
} as const;

export const APP_BACKEND_URL = APP_BACKEND_URLS[ENVIRONMENT];
