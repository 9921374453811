import { ImageDataLike } from "gatsby-plugin-image";
import { ENVIRONMENT } from "../EnvironmentHelper/EnvironmentHelper";

export type NominalType<
	Identifier extends string,
	PrimitiveType
> = PrimitiveType & { [k in Identifier]: void };

export type BlogCategories = NominalType<"BlogCategories", string>;

export const getCategory = (node: BlogNode) =>
	node?.relationships?.field_blog_category?.name;

export const getBlogCategories = (): BlogCategories[] => {
	if (ENVIRONMENT === "development") {
		return [
			"All",
			"Data Management",
			"Scientific Research",
			"What's new",
			"Events",
			"Test",
		] as BlogCategories[];
	}
	return [
		"All",
		"Data Management",
		"Scientific Research",
		"What's new",
		"Events",
	] as BlogCategories[];
};

/**
 * Filter function to remove test blog category in prod.
 */
export const filterBlogCategoriesForEnv = (blogNode: BlogNode): boolean =>
	getBlogCategories().includes(getCategory(blogNode));

export const getBlogCategoryName = (blogCategory: BlogCategories): string =>
	((
		{
			"Data Management": "Data management",
			"Scientific Research": "Scientific research",
			"Events": "Events",
		} as Record<BlogCategories, string>
	)[blogCategory] || blogCategory);

export type CmsNode = {
	title: string;
	body: {
		summary: string;
	};
	created: string;
	path: {
		alias: string;
	};
	relationships: {
		field_feature_image: {
			relationships: {
				field_media_image: {
					localFile: ImageDataLike;
				};
			};
			field_media_image: {
				alt: string;
			};
		};
	};
};

export type BlogNode = {
	title: string;
	body: {
		summary: string;
	};
	created: string;
	path: {
		alias: string;
  };
  metaTitle?: string
	relationships: {
		field_blog_category: {
			name: BlogCategories;
		};
		field_blog_feature_image: {
			relationships: {
				field_media_image: {
					localFile: ImageDataLike;
				};
			};
			field_media_image: {
				alt: string;
			};
		};
	};
};

export const blogNodeToCmsNode = (node: BlogNode): CmsNode => ({
	...node,
	relationships: {
		field_feature_image: node.relationships.field_blog_feature_image,
	},
});
